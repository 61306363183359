export const FeatureFlags = {
    FEATURE_MANAGER_V1: 'FEATURE_MANAGER_V1',
    DEVICE_MANAGER_V1: 'DEVICE_MANAGER_V1',
    DEVICE_VIEWER_V1: 'DEVICE_VIEWER_V1',
    ACCOUNT_ADMIN_V1: 'ACCOUNT_ADMIN_V1',
    ACTIVATION_MANAGER_V1: 'ACTIVATION_MANAGER_V1',
    ACTIVATIONS_VIEWER_V1: 'ACTIVATIONS_VIEWER_V1',
    JOBS_MANAGER_V1: 'JOBS_MANAGER_V1',
    JOBS_VIEWER_V1: 'JOBS_VIEWER_V1',
    FEATURE_FLAG_MANAGER_V1: 'FEATURE_FLAG_MANAGER_V1',
    GLOBAL_ADMIN_V1: 'GLOBAL_ADMIN_V1',
    DOCUMENT_MANAGER_V1: 'DOCUMENT_MANAGER_V1',
    DOCUMENT_VIEWER_V1: 'DOCUMENT_VIEWER_V1',
    CUSTOMIZABLE_SHARING_STATION_V1: 'CUSTOMIZABLE_SHARING_V1',
    LEGACY_ADMIN_V1: 'LEGACY_ADMIN_V1',
    ACTIVATION_TEMPLATES_V1: 'ACTIVATION_TEMPLATES_V1',
    EXPERIENCE_BUILDER_V1: 'EXPERIENCE_BUILDER_V1',
    SURVEY_URL_V1: 'SURVEY_URL_V1',
};
